import React from 'react';
import ReactGA from 'react-ga';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import movie18 from '../videos/18Resources.mp4';
import movie19 from '../videos/19_Visualizations.mp4';
import movie20 from '../videos/20_LiveBinders.mp4';
import movie21 from '../videos/21_Newsletters.mp4';
import pic1Resources from '../images/pic1Resources.jpeg';
import pic2Resources from '../images/pic2Resources.png';
import pic3Resources from '../images/pic3Resources.png';
import pic4Resources from '../images/pic4Resources.png';
import pic5Resources from '../images/pic5Resources.png';
import pic6Resources from '../images/pic6Resources.jpeg';
import pic7Resources from '../images/pic7Resources.png';
import pic8Resources from '../images/pic8Resources.jpeg';
import pic9Resources from '../images/pic9Resources.png';
import pic10Resources from '../images/pic10Resources.png';
import pic11Resources from '../images/pic11Resources.png';
import pic12Resources from '../images/pic12Resources.png';
import pic13Resources from '../images/pic13Resources.jpeg';
import pic14Resources from '../images/pic14Resources.jpeg';


export default function Resources() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie18 | ''>(movie18);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie18);
    }

    const setVideoClip = (src: typeof movie18) => {
        setPlay(true);
        setClip(src);
    }
    return (
        <>
        <DashboardLayout>
            <Container fluid className="isrd">
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col className="headingBG banner">
                                <h2>Resources</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div onMouseOver={() => setVideoClip(movie18)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                    <Row className="resources-row">
                        <Col xs={12} md={3} lg={3} className="text-center">
                            <img src={pic1Resources} alt="" />
                        </Col>
                        <Col xs={12} md={9} lg={9} >
                            <h5>Program Accountability, Assessment & Data System</h5>
                            <a href="https://www.fldoe.org/academics/exceptional-student-edu/data/" target="_blank" rel="noreferrer"><h6>LEA PROFILES</h6></a>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={4} lg={4} className="text-center">
                        <a href="https://www.hopeforhealingfl.com/" target="_blank" rel="noreferrer"><img src={pic2Resources} alt="" /></a>
                        </Col>
                        <Col xs={12} md={4} lg={4} className="text-center">
                        <a href="https://www.safeschoolsforalex.org/" target="_blank" rel="noreferrer"><img src={pic3Resources} alt="" /></a>
                        </Col>
                        <Col xs={12} md={4} lg={4} className="text-center">
                        <a href="https://www.fldoe.org/academics/exceptional-student-edu/beess-resources/presentations-pubs/weekly-memo.stml" target="_blank" rel="noreferrer"><img src={pic4Resources} alt="" /></a>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={4} lg={4} className="text-center">
                        <a href="https://getfortifyfl.com/"><img src={pic5Resources} alt="" /></a>
                        </Col>
                        <Col xs={12} md={4} lg={4} className="text-center">
                        <a href="https://suicidepreventionlifeline.org/" target="_blank" rel="noreferrer"><img src={pic6Resources} alt="" /></a>
                        </Col>
                        <Col xs={12} md={4} lg={4} className="text-center">
                        <a href="https://vetoviolence.cdc.gov/apps/aces-training/#/#top"><img src={pic7Resources} alt="" /></a>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={6} lg={6} className="text-center">
                        <img src={pic8Resources} alt="" />
                        </Col>
                        <Col xs={12} md={6} lg={6} className="text-center">
                            <a href="https://fl-pda.org/#/home" target="_blank" rel="noreferrer">
                                <h2>BEESS Portal</h2>
                             </a>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={5} lg={5} className="text-center">
                        <img src={pic9Resources} alt="" />
                        </Col>
                        <Col xs={12} md={7} lg={7}>
                            <h4>Did you know?</h4>
                            <p>Did you know that BEESS partners with LRP Publications to provide all Florida school districts with a subscription to Special Ed Connection®? <a href='https://go.lrp.com/sec21_splash' target="_blank" rel="noreferrer">Click HERE</a> to learn more!  </p>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={5} lg={5} className="text-center">
                        <img src={pic10Resources} alt="" />
                        </Col>
                        <Col xs={12} md={7} lg={7}>
                            <h4>ESE & SS Directors - 12 Month Check List</h4>
                            <p>Looking for a detailed list of what each month brings to your world?</p>
                            <p><a href="https://go.lrp.com/sec21_splash" target="_blank" rel="noreferrer">Click HERE</a> to learn more!</p>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={5} lg={5} className="text-center">
                        <img src={pic11Resources} alt="" />
                        </Col>
                        <Col xs={12} md={7} lg={7}>
                        <h4>BEESS Discretionary Projects Virtual Instruction Resources</h4>
                        <p>The Bureau of Exceptional Student Education (BEESS) Discretionary Project is gathering resources they utilize or are aware of to assist educators in meeting the needs of all learners in alternate settings.  During these challenging times, <a href="https://www.fdlrs.org/about/educators/virtual-instructional-resources" target="_blank" rel="noreferrer">BEESS</a> hopes this "One Stop Shop for Resources" might be of assistance to you and your educators.</p>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={5} lg={5} className="text-center">
                        <a href="https://sednetfl.info/Resources.aspx"><img src={pic12Resources} alt="" /></a>
                        </Col>
                        <Col xs={12} md={7} lg={7}>
                           <a href="https://www.livebinders.com/b/2565003#anchor" target="_blank" rel="noreferrer"><h4>Florida Local Youth Mental Health Resource Guides</h4></a>
                           <p>A collection of resource guides from Florida school districts.</p>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={5} lg={5} className="text-center">
                        <img src={pic13Resources} alt="" />
                        </Col>
                        <Col xs={12} md={7} lg={7}>
                            <a href='https://resources.finalsite.net/images/v1597845387/fdlrsorg/zhxrg9iraxj1ca3pdp7f/size-alike_map_2019_final.pdf' target="_blank" rel="noreferrer"><h4>Florida Size-Alike Map</h4></a>
                        </Col>
                    </Row>
                    <Row className="resources-row">
                        <Col xs={12} md={5} lg={5} className="text-center">
                        <img src={pic14Resources} alt="" />
                        </Col>
                        <Col xs={12} md={7} lg={7}>
                            <a href='https://www.fldoe.org/core/fileparse.php/7672/urlt/CalcGuide19.pdf' target="_blank" rel='noreferrer'><h4>Calculation Guide for Florida's State & Annual Performance Resports</h4></a>
                        </Col>
                    </Row>
                </div>
                <Row className="resources-button-groups">
                <Col xs={12} md={6} lg={6}>
                <div onMouseOver={() => setVideoClip(movie19)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                    <h5>Visualizations and Reports</h5>
                    <div><Button className='resources-button' href="https://www.fldoe.org/academics/exceptional-student-edu/data/index.stml" target="_blank">SEA & LEA Profiles</Button></div>
                    <div><Button className='resources-button' href="https://app.powerbi.com/view?r=eyJrIjoiMmY5MzEyNzctYzhmZC00N2FlLWI1NzQtZDdlZDZhNDlhYWFhIiwidCI6Ijc1YzM1ODAxLTIwZTctNGRjMC04ZjQ5LWFiZGJmNTQ4OTRiZSIsImMiOjF9" target="_blank">Launch LEA Data Visualization</Button></div>
                    <div><Button className='resources-button' href="https://www.fldoe.org/academics/exceptional-student-edu/monitoring/index.stml" target="_blank">Monitoring Reports (2001-Present)</Button></div>
                </div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                <div onMouseOver={() => setVideoClip(movie20)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                <h5>LiveBinders</h5>
                    <div><Button className='resources-button' href="https://www.livebinders.com/shelf/view/203094?id=203094" target="_blank">ESE Directors</Button></div>
                    <div><Button className='resources-button' href="https://www.livebinders.com/shelf/view/203096?id=203096" target="_blank">Principals</Button></div>
                    <div><Button className='resources-button' href="https://www.livebinders.com/shelf/view/203098?id=203098" target="_blank">School Counselors</Button></div>
                    <div><Button className='resources-button' href="https://www.livebinders.com/shelf/view/185854?id=185854" target="_blank">Staffing Specialist</Button></div>
                    <div><Button className='resources-button' href="https://www.livebinders.com/shelf/view/185855?id=185855" target="_blank">School Psychologist</Button></div>
                </div>
                </Col>
                <Col xs={12} md={6} lg={6} id='newsletters'>
                <div onMouseOver={() => setVideoClip(movie21)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                <h5>Newsletters</h5>
                <h6 className='resources-h6'>2021-2022</h6>
                <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-2--21-22.html?soid=1102375384851&aid=u4P4klQ2njE" target="_blank">Quarter 2</Button></div>
                <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-1--21-22.html?soid=1102375384851&aid=DesWC1R7S2I" target="_blank">Quarter 1</Button></div>
                <h6 className='resources-h6'>2020-2021</h6>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=VYfPe_hcIOg" target="_blank">Quarter 4</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=VJNnC7ImZ0c" target="_blank">Quarter 3</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=bkSULSDL5Mg" target="_blank">Quarter 2</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=WcCo1k8EV7k" target="_blank">Quarter 1</Button></div>
                    <h6 className='resources-h6'>2019-2020</h6>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2019-20.html?soid=1102375384851&aid=4bLsBTw3p1Y" target="_blank">Quarter 4</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-3--2019-20.html?soid=1102375384851&aid=AbT-7tEKrXg" target="_blank">Quarter 3</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-2--2019-20.html?soid=1102375384851&aid=-0iiKqW7I4M" target="_blank">Quarter 2</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-1--2019-20.html?soid=1102375384851&aid=USmJ1QBQHzc" target="_blank">Quarter 1</Button></div>
                    <h6 className='resources-h6'>2018-2019</h6>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2018-19.html?soid=1102375384851&aid=SWRlDw2XOlc" target="_blank">Quarter 4</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-3--2018-19.html?soid=1102375384851&aid=QeptPJSQx_c" target="_blank">Quarter 3</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-2--2018-19.html?soid=1102375384851&aid=ls4n3jg4FUs" target="_blank">Quarter 2</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2017-18.html?soid=1102375384851&aid=ISBBjq6mVdw" target="_blank">Quarter 1</Button></div>
                    <h6 className='resources-h6'>2017-2018</h6>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2017-18.html?soid=1102375384851&aid=qNV2QIVmAmo" target="_blank">Quarter 4</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Quarter-3-Newsletter-2017-18.html?soid=1102375384851&aid=wP9HhqcdcgI" target="_blank">Quarter 3</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Quarter-2-Newsletter-2017-18.html?soid=1102375384851&aid=j2HObFg2Sio" target="_blank">Quarter 2</Button></div>
                    <div><Button className='resources-button' href="https://myemail.constantcontact.com/ISRD-Quarter-1-Newsletter-2017-18.html?soid=1102375384851&aid=RTtG02_89v0" target="_blank">Quarter 1</Button></div>
                </div>
                </Col>
            </Row>
                <Row className="footer">
                        <Col className="center">
                            Institute for Small & Rural Districts<br />
                            3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                        </Col>
                    </Row>
            </Container>
        </DashboardLayout>
        {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
