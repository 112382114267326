import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from '../pages/About';
import ContactList from '../pages/ContactList';
import MemberDistricts from '../pages/MemberDistricts';
import Events from '../pages/Events';
import AnnualReport from '../pages/AnnualReport';
import Resources from '../pages/Resources';
import ContactUs from '../pages/ContactUs';
import Home from '../pages/Home';
import RecordedWebinars from '../pages/RecordedWebinars';
import Newsletters from '../pages/Newsletters';

export const Routing = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/newsletters' element={<Newsletters />}/>
            <Route path='/about' element={<About />} />
            <Route path="/contact-lists" element={<ContactList />} />
            <Route path="/member-districts" element={<MemberDistricts />} />
            <Route path="/events" element={<Events />} />
            <Route path="/annual-report" element={<AnnualReport />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/resources/recorded-webinars" element={<RecordedWebinars />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route element={<Home />} />
        </Routes>
    )
}
