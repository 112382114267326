import React from 'react';
import ReactGA from 'react-ga';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import movie17 from '../videos/17AnnualReport.mp4';


export default function AnnualReport() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie17 | ''>(movie17);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie17);
    }

    const setVideoClip = (src: typeof movie17) => {
        setPlay(true);
        setClip(src);
    }
    return (
        <>
            <DashboardLayout>
                <Container fluid className="isrd">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Col className="headingBG banner">
                                    <h2>Annual Report</h2>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12} className="annualReportPDF">
                            <div onMouseOver={() => setVideoClip(movie17)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                                <embed
                                    src={'https://drive.google.com/file/d/1kIP7Bm8dxNcotpBzKtws4UoCce0V-byC/preview'}
                                    height="900"
                                    width="100%"></embed>
                            </div>
                        </Col>
                    </Row>
                    <Row className="footer">
                        <Col className="center">
                            Institute for Small & Rural Districts<br />
                            3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                        </Col>
                    </Row>
                </Container>
            </DashboardLayout>
            {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
