/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import { Navigation } from "react-minimal-side-navigation";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { GiHamburgerMenu } from 'react-icons/gi';
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import isrdLogo from '../images/isrd_logo.jpeg';

export const NavSidebar = () => {
  const history = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <React.Fragment>
      {/* Sidebar Overlay */}
      <div
        onClick={() => setIsSidebarOpen(false)}
        className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
          isSidebarOpen ? "block" : "hidden"
        }`}
      />
        <button
          className="fixed left-0 z-50 btn-menu lg:hidden hamburger"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          type="button"
        >
          <GiHamburgerMenu className="w-6 h-6" color="#ffffff" />
        </button>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-30 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-0 lg:translate-x-0 lg:static lg:inset-0 ${
          isSidebarOpen ? "ease-out translate-x-0" : "ease-in -translate-x-full"
        }`}
      >
        <div className="flex items-center justify-center mt-10 text-center py-6">
          <span className="mx-2 text-2xl font-semibold text-black">
            <img src={isrdLogo} alt="" className="isrd-logo" />
          </span>
        </div>

        <Navigation
  activeItemId={location.pathname}
  onSelect={({ itemId }) => {
    // Check if the itemId is the external link
    if (itemId === "/main-site") {
      // Use window.location.href to navigate to the external site
      window.location.href = "https://sites.google.com/nefec.org/isrd/home?authuser=0"; // Put the actual URL here
    } else {
      // For internal navigation, use the existing history method
      history(itemId);
    }
  }}
          items={[
            {
              title: "Home",
              itemId: "/",
            //   elemBefore: () => <Icon name="coffee" />
            },
            {
              title: "About ISRD",
              itemId: "/about",
            //   elemBefore: () => <Icon name="user" />,
            //   subNav: [
            //     {
            //       title: "Projects",
            //       itemId: "/about/projects"
            //     },
            //     {
            //       title: "Members",
            //       itemId: "/about/members"
            //     }
            //   ]
            },
            {
                title: "Contact Lists",
                itemId: "/contact-lists",
              //   elemBefore: () => <Icon name="coffee" />
              },
              {
                title: "Member Districts",
                itemId: "/member-districts",
              //   elemBefore: () => <Icon name="coffee" />
              },
              {
                title: "Calendar of Events",
                itemId: "/events",
              //   elemBefore: () => <Icon name="coffee" />
              },
              {
                title: "Annual Report",
                itemId: "/annual-report",
              //   elemBefore: () => <Icon name="coffee" />
              },
              {
                title: "Resources",
                itemId: "/resources",
                subNav: [
                {
                  title: "Recorded Webinars",
                  itemId: "/resources/recorded-webinars"
                },
              ]
              },
              {
                title: "Contact Us",
                itemId: "/contact-us",
              //   elemBefore: () => <Icon name="coffee" />
              },
              {
                title: "View Main Site",
                itemId: "/main-site",
              }

          ]}
        />

        {/* <div className="absolute bottom-0 w-full my-8">
          <Navigation
            activeItemId={location.pathname}
            items={[
              {
                title: "Settings",
                itemId: "/settings",
                // elemBefore: () => <Icon name="activity" />
              }
            ]}
            onSelect={({ itemId }) => {
              history.push(itemId);
            }}
          />
        </div> */}
      </div>
    </React.Fragment>
  );
};