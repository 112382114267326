import React from 'react';
import ReactGA from 'react-ga';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import { Container, Row, Col } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import chastityPic from '../images/ChastityLee.jpeg';
import kaciePic from '../images/KacieHarley.jpeg';
import kelliePic from '../images/KellieGuilfoyle.jpeg';
import movie23 from '../videos/23ContactUs.mp4';

export default function ContactUs() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie23 | ''>(movie23);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie23);
    }

    const setVideoClip = (src: typeof movie23) => {
        setPlay(true);
        setClip(src);
    }

    return (
        <>
        <DashboardLayout>
            <Container fluid className="isrd">
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col className="headingBG banner">
                                <h2>Contact Us</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div onMouseOver={() => setVideoClip(movie23)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                <Row>
                    <Col xs={12} md={4} lg={4} className="justify-content-start text-center padding-top">
                    <a href="http://www.nefec.org/staff/173/chastity-lee/" target="_blank" rel="noreferrer">
                        <img src={chastityPic} alt="" /></a>
                        <a href="http://www.nefec.org/staff/173/chastity-lee/" target="_blank" rel="noreferrer"><h2>Chastity Lee</h2></a>
                        <h5>Program Administrator</h5>
                    </Col>
                    <Col xs={12} md={4} lg={4} className="justify-content-start text-center padding-top">
                        <a href="http://www.nefec.org/staff/155/kellie-guilfoyle/" target="_blank" rel="noreferrer">
                        <img src={kelliePic} alt="" /></a>
                        <a href="http://www.nefec.org/staff/155/kellie-guilfoyle/" target="_blank" rel="noreferrer"><h2>Kellie Guilfoyle</h2></a>
                        <h5>Program Specialist</h5>
                    </Col>
                    <Col xs={12} md={4} lg={4} className="justify-content-start text-center padding-top">
                        <a href="mailto:harleyk@nefec.org" target="_blank" rel="noreferrer">
                        <img src={kaciePic} alt="" /></a>
                        <a href="mailto:harleyk@nefec.org" target="_blank" rel="noreferrer"><h2>Kacie Harley</h2></a>
                        <h5>Support Specialist</h5>
                    </Col>
                </Row>
                </div>
                <Row className="footer">
                        <Col className="center">
                            Institute for Small & Rural Districts<br />
                            3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                        </Col>
                    </Row>
            </Container>
        </DashboardLayout>
        {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
    </>
    )
}
