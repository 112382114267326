import * as React from 'react';
import { Image } from 'react-bootstrap';
import movie1 from '../../clients/ISRD/videos/ourMission.mp4';
import ReactPlayer from 'react-player';
import cirrusLabsLogo from '../../images/cirrus-labs-small.svg';

type Props = {
    src: typeof movie1,
    playVideo: boolean,
}

// export const Banner = ({ dataSource }: BannerProps) => {
    export const VideoPlayer = ({ src, playVideo}: Props) => {

        return (
            <div className='player-wrapper'>
                <ReactPlayer
                    className="react-player paper-shadow"
                    light={false}
                    url={[{
                        src: src, type: 'video/mp4'
                    }]} muted={true} pip={true} playing={playVideo} playsinline={true} auto/>
                    <div className="player-footer">
                    <a href="https://www.cirrus-inc.com/labs" target="_blank" rel="noreferrer"><Image className="cirrus-labs-logo" src={cirrusLabsLogo} /></a>
                    </div>
                    </div>
                )
        
    }
