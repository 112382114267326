import React from 'react';
import ReactGA from 'react-ga';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import { Container, Row, Col } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import panhandleRegion from '../images/panhandle_region.jpeg';
import juliePic from '../images/JulieWeatherly.jpeg';
import timPic from '../images/DrTimothyKing.jpeg';
import scottPic from '../images/DrScottPoland.jpeg';
import movie22 from '../videos/22RecordedWebinars.mp4';

export default function RecordedWebinars() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie22 | ''>(movie22);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie22);
    }

    const setVideoClip = (src: typeof movie22) => {
        setPlay(true);
        setClip(src);
    }
    return (
        <>
            <DashboardLayout>
                <Container fluid className="isrd">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Col className="headingBG banner">
                                    <h2>Recorded Webinars</h2>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div onMouseOver={() => setVideoClip(movie22)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                        <Row className="recorded-webinars-rows">
                            <Col xs={12} md={2} lg={2} className="justify-content-start text-center">
                                <img src={juliePic} alt="" />
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                                <h2>Julie Weatherly</h2>
                                <h5>Resolutions in Special Education, Inc.</h5>
                            </Col>
                            <Col xs={12} md={5} lg={5}> 
                               <a className='non-link'target="_blank" rel="noreferrer">Strategies to Avoid and Respond to Claims of Retaliation</a>
                               <br /><br /><a className='non-link'target="_blank" rel="noreferrer">Common Legal Mistakes Related to the Provision of ESY Services to Students with Disabilities </a>
                               <br /><br /><a className='non-link'target="_blank" rel="noreferrer">Making Appropriate Manifestation Determinations:  Who, What, Why, When and How? </a>
                               <br /><br /><a className='webinar-link' href='https://isrd.adobeconnect.com/pgxomt2slt2c/'target="_blank" rel="noreferrer">Balancing School Safety Mandates with Special Education Legal Requirements:What School Administrators Need to Know </a>
                               <br /><br /><a className='webinar-link' href='https://isrd.adobeconnect.com/pb6u6xulioyy/'target="_blank" rel="noreferrer">Quick Tips on the Rules of Discipline for Students with Disabilities </a>
                               <br /><br /><a className='webinar-link' href='https://isrd.adobeconnect.com/p3202lv5a56v/'target="_blank" rel="noreferrer">Understanding Important Parent Rights and Procedural Safeguards under IDEA </a>
                               <br /><br /><a className='webinar-link' href='https://isrd.adobeconnect.com/p4stsm4py8ii/'target="_blank" rel="noreferrer">Developing Defensible IEPs Part I:  Common Procedural Errors to Avoid </a>
                               <br /><br /><a className='webinar-link' href='https://isrd.adobeconnect.com/p01cffzp025z/'target="_blank" rel="noreferrer">Developing Defensible IEPs Part II:  Common Content Errors to Avoid </a>
                            </Col>
                        </Row>
                        <br /><br /><Row className="recorded-webinars-rows">
                            <Col xs={12} md={2} lg={2} className="justify-content-start text-center">
                                <img src={timPic} alt="" />
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                                <h2>Dr. Timothy King</h2>
                                <h5>Crowning Moments Consultants</h5>
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                            <a className='webinar-link' href='https://isrd.adobeconnect.com/ps9i1hfzgajb/'target="_blank" rel="noreferrer">The Disability of School Discipline </a>
                            <br /><br /><a className='non-link' target="_blank" rel="noreferrer">Restorative Practices and Students with Disabilities </a>
                            </Col>
                        </Row>
                        <br /><br /><Row className="recorded-webinars-rows">
                            <Col xs={12} md={2} lg={2} className="justify-content-start text-center">
                                <img src={scottPic} alt="" />
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                                <h2>Dr. Scott Poland</h2>
                                <h5>Nova Southeastern University</h5>
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                            <a className='webinar-link' href='https://isrd.adobeconnect.com/pynpnccqs3e1/'target="_blank" rel="noreferrer"> The Florida School Toolkit for Educators to Prevent Suicide (STEPS) Overview</a>
                               <br /><br /><a className='webinar-link' href='https://isrd.adobeconnect.com/pbu569gxaksg/'target="_blank" rel="noreferrer">Youth Suicide: Prevention, Intervention and Postvention in Schools </a>
                               <br /><br /><a className='webinar-link' href='https://isrd.adobeconnect.com/pjyfiaj0qhzt/'target="_blank" rel="noreferrer">School Violence Prevention </a>
                            </Col>
                        </Row>
                    </div>
                    <Row className="footer">
                        <Col className="center">
                            Institute for Small & Rural Districts<br />
                            3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                        </Col>
                    </Row>
                </Container>
            </DashboardLayout>
            {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
