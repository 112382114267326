import React from 'react';
import ReactGA from 'react-ga';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import movie21 from '../videos/21_Newsletters.mp4';

export default function Resources() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie21 | ''>(movie21);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie21);
    }

    const setVideoClip = (src: typeof movie21) => {
        setPlay(true);
        setClip(src);
    }
    return (
        <>
        <DashboardLayout>
            <Container fluid className="isrd">
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col className="headingBG banner">
                                <h2>Newsletters</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div onMouseOver={() => setVideoClip(movie21)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                <Row className='newsletter-row'>
                <Col xs={6} md={3} lg={3} id='newsletter-list'>
                    <h6 className='newsletter-h6'>2020-2021</h6>
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=VYfPe_hcIOg" target="_blank" rel="noreferrer">2020-21 Quarter 4 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=VJNnC7ImZ0c" target="_blank" rel="noreferrer">2020-21 Quarter 3 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=bkSULSDL5Mg" target="_blank" rel="noreferrer">2020-21 Quarter 2 Newsletter</a></div>
                   <br /> 
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/Institute-for-Small---Rural-Districts-Newsletter.html?soid=1102375384851&aid=WcCo1k8EV7k" target="_blank" rel="noreferrer">2020-21 Quarter 1 Newsletter</a></div>
                </Col>

                <Col xs={6} md={3} lg={3} id='newsletter-list'>
                    <h6 className='newsletter-h6'>2019-2020</h6>
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2019-20.html?soid=1102375384851&aid=4bLsBTw3p1Y" target="_blank" rel="noreferrer">2019-20 Quarter 4 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-3--2019-20.html?soid=1102375384851&aid=AbT-7tEKrXg" target="_blank" rel="noreferrer">2019-20 Quarter 3 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-2--2019-20.html?soid=1102375384851&aid=-0iiKqW7I4M" target="_blank" rel="noreferrer">2019-20 Quarter 2 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-1--2019-20.html?soid=1102375384851&aid=USmJ1QBQHzc" target="_blank" rel="noreferrer">2019-20 Quarter 1 Newsletter</a></div>
                </Col>  

                <Col xs={6} md={3} lg={3} id='newsletter-list'>
                    <h6 className='newsletter-h6'>2018-2019</h6>
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2018-19.html?soid=1102375384851&aid=SWRlDw2XOlc" target="_blank" rel="noreferrer">2018-19 Quarter 4 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-3--2018-19.html?soid=1102375384851&aid=QeptPJSQx_c" target="_blank" rel="noreferrer">2018-19 Quarter 3 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-2--2018-19.html?soid=1102375384851&aid=ls4n3jg4FUs" target="_blank" rel="noreferrer">2018-19 Quarter 2 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2017-18.html?soid=1102375384851&aid=ISBBjq6mVdw" target="_blank" rel="noreferrer">2018-19 Quarter 1 Newsletter</a></div>
                </Col>

                <Col xs={6} md={3} lg={3} id='newsletter-list'>
                    <h6 className='newsletter-h6'>2017-2018</h6>
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-4--2017-18.html?soid=1102375384851&aid=qNV2QIVmAmo" target="_blank" rel="noreferrer">2017-18 Quarter 4 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Quarter-3-Newsletter-2017-18.html?soid=1102375384851&aid=wP9HhqcdcgI" target="_blank" rel="noreferrer">2017-18 Quarter 3 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Quarter-2-Newsletter-2017-18.html?soid=1102375384851&aid=j2HObFg2Sio" target="_blank" rel="noreferrer">2017-18 Quarter 2 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Quarter-1-Newsletter-2017-18.html?soid=1102375384851&aid=RTtG02_89v0" target="_blank" rel="noreferrer">2017-18 Quarter 1 Newsletter</a></div>
                </Col>
                </Row>
                <Row className='newsletter-row'>
                <Col xs={6} md={3} lg={3} id='newsletter-list'>
                <h6>2021-2022</h6>
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-2--21-22.html?soid=1102375384851&aid=u4P4klQ2njE" target="_blank" rel="noreferrer">2021-22 Quarter 2 Newsletter</a></div>
                    <br />
                    <div><a className='newsletter-link' href="https://myemail.constantcontact.com/ISRD-Newsletter--Quarter-1--21-22.html?soid=1102375384851&aid=DesWC1R7S2I" target="_blank" rel="noreferrer">2021-22 Quarter 1 Newsletter</a></div>
                    
                </Col>
                </Row>
                
                </div>
                <Row className="footer">
                        <Col className="center">
                            Institute for Small & Rural Districts<br />
                            3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                        </Col>
                    </Row>
            </Container>
        </DashboardLayout>
        {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
