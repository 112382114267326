import React from 'react';
import ReactGA from 'react-ga';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import { Container, Row, Col } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import panhandleRegion from '../images/PAEC Region Large.png';
import northeastRegion from '../images/NEFEC Region Large.png';
import heartlandRegion from '../images/HEC Region.png';
import movie15 from '../videos/15MemberDistricts.mp4';

export default function MemberDistricts() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie15 | ''>(movie15);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie15);
    }

    const setVideoClip = (src: typeof movie15) => {
        setPlay(true);
        setClip(src);
    }
    return (
        <>
        <DashboardLayout>
            <Container fluid className="isrd member-districts">
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Row>
                            <Col className="headingBG banner">
                                <h2>Member Districts</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div onMouseOver={() => setVideoClip(movie15)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                    <Row>
                        <Col xs={12} md={6} lg={6} className="justify-content-start text-center">
                            <h4>Panhandle Region</h4>
                            <img src={panhandleRegion} alt="Panhandle Region" className="regionMap" />
                        </Col>
                        <Col xs={12} md={6} lg={6} className="memberDistrictList">
                            <Row>
                                <Col lg={6}>
                                    <ul>
                                        <li><strong>
                                            <a href="http://www.calhounflschools.org/" target="_blank" rel="noreferrer">Calhoun</a></strong><br />
                                            <a href="mailto:staci.williams@calhounflschools.org" target="_blank" rel="noreferrer">Staci Williams</a>
                                        </li>
                                        <li><strong>
                                           <a href="https://www.famudrs.org/" target="_blank" rel="noreferrer">FAMU Lab School</a></strong><br />
                                           <a href="https://www.famudrs.org/apps/email/index.jsp?e=40664040405840404040393040504040406640664080396640424032405640723930404040384072&n=Renee%20Jerry" target="_blank" rel="noreferrer">Renee Jerry</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.dc.state.fl.us/development/index.html" target="_blank" rel="noreferrer">FL Dept. of Corrections</a></strong><br />
                                            <a href="mailto:anna.schubarth@fdc.myflorida.com" target="_blank" rel="noreferrer">Anna Schubarth</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.franklincountyschools.org/" target="_blank" rel="noreferrer">Franklin</a></strong><br />
                                            <a href="mailto:tshenuski@fcsdfl.org" target="_blank" rel="noreferrer">Dr. Tracy Shenuski</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.fsus.school/" target="_blank" rel="noreferrer">FSU Lab School</a></strong><br />
                                            <a href="mailto:mbroome@fsu.edu" target="_blank" rel="noreferrer">Monica Broome</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.gcps.k12.fl.us/" target="_blank" rel="noreferrer">Gadsden</a></strong><br />
                                            <a href="mailto:brownk@gcpsmail.com">Kesandra Brown</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://gulfcoschools.com/" target="_blank" rel="noreferrer">Gulf</a></strong><br />
                                            <a href="mailto:jmock@gulf.k12.fl.us" target="_blank" rel="noreferrer">Joni Mock</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.hdsb.org/" target="_blank" rel="noreferrer">Holmes</a></strong><br />
                                            <a href="mailto:donnita.butorac@hdsb.org" target="_blank" rel="noreferrer">Donnita Butorac</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={6} className="memberDistrictList">
                                    <ul>
                                        <li><strong>
                                            <a href="https://www.jcsb.org/" target="_blank" rel="noreferrer">Jackson</a></strong><br />
                                            <a href="mailto:Kristy.Halley@jcsb.org" target="_blank" rel="noreferrer">Kristy Halley</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.jeffersonschooldistrict.org/" target="_blank" rel="noreferrer">Jefferson</a></strong><br />
                                            <a href="mailto:ahoward@jeffersonschools.net" target="_blank" rel="noreferrer">Allyn Howard</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.lcsbonline.org/" target="_blank" rel="noreferrer">Liberty</a></strong><br />
                                            <a href="mailto:lara.deason@lcsb.org" target="_blank" rel="noreferrer">Lara Deason</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.madison.k12.fl.us/" target="_blank" rel="noreferrer">Madison</a></strong><br />
                                            <a href="mailto:melinda.richie@madison.k12.fl.us" target="_blank" rel="noreferrer">Melinda Richie</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.taylor.k12.fl.us/" target="_blank" rel="noreferrer">Taylor</a></strong><br />
                                            <a href="mailto:sabrina.lytle@taylor.k12.fl.us" target="_blank" rel="noreferrer">Sabrina Lytle</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://wakulla.schooldesk.net/" target="_blank" rel="noreferrer">Wakulla</a></strong><br />
                                            <a href="mailto:Belinda.Mcelroy@wcsb.us" target="_blank" rel="noreferrer">Belinda Crowson</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.walton.k12.fl.us/" target="_blank" rel="noreferrer">Walton</a></strong><br />
                                            <a href="mailto:Stefanie.West@walton.k12.fl.us" target="_blank" rel="noreferrer" >Stefanie West</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.wcsdschools.com/"target="_blank" rel="noreferrer" >Washington</a></strong><br />
                                            <a href="mailto:julie.jeffries@wcsdschools.com" target="_blank" rel="noreferrer">Julie Jeffries</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={12} md={6} lg={6} className="justify-content-start text-center">
                            <h4>Northeast Region</h4>
                            <img src={northeastRegion} alt="Northeast Region" className="regionMap" />
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Row>
                                <Col lg={6} className="memberDistrictList">
                                    <ul>
                                        <li><strong>
                                            <a href="https://www.bakerk12.org/" target="_blank" rel="noreferrer">Baker</a></strong><br />
                                            <a href="mailto:Michael.green@bakerk12.org" target="_blank" rel="noreferrer">Michael Green</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.bradfordschools.org/" target="_blank" rel="noreferrer">Bradford</a></strong><br />
                                            <a href="mailto:saxon.tammy@mybradford.us" target="_blank" rel="noreferrer">Tammy Saxon</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.citrus.k12.fl.us/" target="_blank" rel="noreferrer">Citrus</a></strong><br />
                                            <a href="mailto:wolfm@citrusschools.org" target="_blank" rel="noreferrer">Mary Wolf</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.columbia.k12.fl.us/" target="_blank" rel="noreferrer">Columbia</a></strong><br />
                                            <a href="mailto:warfelj@columbiak12.com" target="_blank" rel="noreferrer">Janell Warfel</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.dixie.k12.fl.us/" target="_blank" rel="noreferrer">Dixie</a></strong><br />
                                            <a href="mailto:alexamills@dixie.k12.fl.us" target="_blank" rel="noreferrer">Alexa Mills</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://flaglerschools.com/" target="_blank" rel="noreferrer">Flagler</a></strong><br />
                                            <a href="mailto:hallidayk@flaglerschools.com" target="_blank" rel="noreferrer">Kimberli Halliday</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.fsdbk12.org/" target="_blank" rel="noreferrer">FSDB</a></strong><br />
                                            <a href="mailto:trejbald@fsdbk12.org" target="_blank" rel="noreferrer">Scott Trejbal</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://gilchristfl.apptegy.us/" target="_blank" rel="noreferrer">Gilchrist</a></strong><br />
                                            <a href="mailto:brockm@mygcsd.org" target="_blank" rel="noreferrer">Mandy Brock</a>
                                        </li>
                                        <li><strong>
                                           <a href="http://www.hamiltonfl.com/" target="_blank" rel="noreferrer">Hamilton</a></strong><br />
                                           <a href="mailto:ann.pinello@hamiltonfl.com" target="_blank" rel="noreferrer">Annie Pinello</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={6} className="memberDistrictList">
                                    <ul>
                                        <li><strong>
                                            <a href="http://lafayette.schooldesk.net/Default.aspx?tabid=61006" target="_blank" rel="noreferrer">Lafayette</a></strong><br />
                                            <a href="mailto:ahingson@lcsbmail.net" target="_blank" rel="noreferrer">Alissa Hingson</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.levyk12.org/" target="_blank" rel="noreferrer">Levy</a></strong><br />
                                            <a href="mailto:Marcy.Young@levyk12.org" target="_blank" rel="noreferrer">Marcy Young</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.nassau.k12.fl.us/" target="_blank" rel="noreferrer">Nassau</a></strong><br />
                                            <a href="mailto:kellypa@nassau.k12.fl.us" target="_blank" rel="noreferrer">Patsi Kelly</a>
                                        </li>
                                        <li><strong>
                                           <a href="https://www.putnamschools.org/" target="_blank" rel="noreferrer">Putnam</a></strong><br />
                                            <a href="mailto:aburnett@my.putnamschools.org" target="_blank" rel="noreferrer">Andrew Burnett</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.sumter.k12.fl.us/" target="_blank" rel="noreferrer">Sumter</a></strong><br />
                                            <a href="mailto:katherine.dustin@sumter.k12.fl.us" target="_blank" rel="noreferrer">Kathy Dustin</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.suwannee.k12.fl.us/" target="_blank" rel="noreferrer">Suwannee</a></strong><br />
                                            <a href="mailto:Kelly.Waters@Suwannee.k12.fl.us" target="_blank" rel="noreferrer">Kelly Waters</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://pkyonge.ufl.edu/" target="_blank" rel="noreferrer">UF Lab School</a></strong><br />
                                            <a href="mailto:kdottshoehnle@pky.ufl.edu" target="_blank" rel="noreferrer">Kim Dotts-Hoehnle</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.union.k12.fl.us/" target="_blank" rel="noreferrer">Union</a></strong><br />
                                            <a href="mailto:whiteheadchr@union.k12.fl.us" target="_blank" rel="noreferrer">Christie Whitehead</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={12} md={6} lg={6} className="justify-content-start text-center">
                            <h4>Heartland Region</h4>
                            <img src={heartlandRegion} alt="Heartland Region" className="regionMap" />
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Row>
                                <Col lg={6} className="memberDistrictList">
                                    <ul>
                                        <li><strong>
                                            <a href="https://www.yourcharlotteschools.net/" target="_blank" rel="noreferrer">Charlotte</a></strong><br />
                                            <a href="mailto:Kristy.Johnson@yourcharlotteschools.net" target="_blank" rel="noreferrer">Kristy Johnson</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.desotoschools.com/" target="_blank" rel="noreferrer">DeSoto</a></strong><br />
                                            <a href="mailto:Christina.McCray@desotoschools.com" target="_blank" rel="noreferrer">Christina McCray</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.fau.edu/education/school-and-k12-programs/" target="_blank" rel="noreferrer">FAU Lab School</a></strong><br />
                                            <a href="mailto:larlt@fau.edu" target="_blank" rel="noreferrer">Lauren Arlt</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.flvs.net/" target="_blank" rel="noreferrer">FLVS</a></strong><br />
                                            <a href="mailto:ncallaghan@flvs.net" target="_blank" rel="noreferrer">Nikki Callaghan</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.gladesedu.org/" target="_blank" rel="noreferrer">Glades</a></strong><br />
                                            <a href="mailto:rhonda.boils@glades-schools.org" target="_blank" rel="noreferrer">Rhonda Boils</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.hardee.k12.fl.us/" target="_blank" rel="noreferrer">Hardee</a></strong><br />
                                            <a href="mailto:ttaylor@hardee.k12.fl.us" target="_blank" rel="noreferrer">Tamara Taylor-Camilo</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.hendry-schools.org/Page/1" target="_blank" rel="noreferrer">Hendry</a></strong><br />
                                            <a href="mailto:whitely@hendry-schools.net">Lynette White</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www2.highlands.k12.fl.us/" target="_blank" rel="noreferrer">Highlands</a></strong><br />
                                            <a href="mailto:lanierp@highlands.k12.fl.us">Pamela Lanier</a>
                                        </li>
                                        <li><strong>
                                            <a href="" target="_blank" rel="noreferrer">IDEA Charter Schools</a></strong><br />
                                            <a href="mailto:gwendalyn.alford@IDEAPublicSchools.org">Gwen Alford</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={6} className="memberDistrictList">
                                    <ul>
                                        <li><strong>
                                            <a href="https://www.indianriverschools.org/" target="_blank" rel="noreferrer">Indian River</a></strong><br />
                                            <a href="mailto:Daphne.Mathews@indianriverschools.org" target="_blank" rel="noreferrer">Daphne Mathews</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.kippmiami.org/" target="_blank" rel="noreferrer">KIPP Miami</a></strong><br />
                                            <a href="mailto:jvaldes@kippmiami.org" target="_blank" rel="noreferrer">Jaclyn Valdes</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://lwcharterschools.com/" target="_blank" rel="noreferrer">Lake Wales CS</a></strong><br />
                                            <a href="mailto:jennifer.barrow@lwcharterschools.com" target="_blank" rel="noreferrer">Jennifer Barrow</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.martinschools.org/" target="_blank" rel="noreferrer">Martin</a></strong><br />
                                            <a href="mailto:almestw@martinschools.org" target="_blank" rel="noreferrer">Wilma Almestica-Sanchez</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.materacademy.org/" target="_blank" rel="noreferrer">Mater LEA</a></strong><br />
                                            <a href="mailto:sbarany@academica.org" target="_blank" rel="noreferrer">Shirrie Barany</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://fl02202360.schoolwires.net/" target="_blank" rel="noreferrer">Monroe</a></strong><br />
                                            <a href="mailto:lesley.thompson@keysschools.com" target="_blank" rel="noreferrer">Lesley Thompson</a>
                                        </li>
                                        <li><strong>
                                            <a href="http://www.okee.k12.fl.us/pages/OkeeCSD" target="_blank" rel="noreferrer">Okeechobee</a></strong><br />
                                            <a href="mailto:kim.cook-morrison@okee.k12.fl.us" target="_blank" rel="noreferrer">Kim Cook-Morrison</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.southtechschools.org/" target="_blank" rel="noreferrer">South Tech Academy</a></strong><br />
                                            <a href="mailto:kristen.markevich@pbcharterschools.org" target="_blank" rel="noreferrer">Kristin Markevich</a>
                                        </li>
                                        <li><strong>
                                            <a href="https://www.ucpcfl.org/" target="_blank" rel="noreferrer">UCP CS</a></strong><br />
                                            <a href="mailto:DHayles@ucpcfl.org" target="_blank" rel="noreferrer">Dr. Diana Hayles-Thomas</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col className="center">
                        Institute for Small & Rural Districts<br />
                        3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                    </Col>
                </Row>
            </Container>
        </DashboardLayout>
        {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
