import React from 'react';
import ReactGA from 'react-ga';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import movie9 from '../videos/09BESEContact.mp4';
import movie10 from '../videos/10FloridaPublicSchoolList.mp4';
import movie11 from '../videos/11BESEDiscretionaryContactList.mp4';
import movie12 from '../videos/12BESEStaffContact.mp4';
import movie13 from '../videos/13BoSSSStaff.mp4';
import movie14 from '../videos/14FLStatewideDiscretionary.mp4';

export default function ContactList() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie9 | ''>(movie9);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie9);
    }

    const setVideoClip = (src: typeof movie9) => {
        setPlay(true);
        setClip(src);
    }
    return (
        <>
        <DashboardLayout>
        <Container fluid className="isrd">
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <Row>
                        <Col className="headingBG banner">
                            <h2>Contact Lists</h2>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="contact-list">
                <Col xs={12} md={4} lg={4}>
                <div onMouseOver={() => setVideoClip(movie9)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                    <Button href="https://www.fldoe.org/academics/exceptional-student-edu/staff/ese-directors.stml" target="_blank">BEESS Statewide ESE Contact Information</Button>
                </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                <div onMouseOver={() => setVideoClip(movie10)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                <Button href="https://www.fldoe.org/accountability/data-sys/school-dis-data/superintendents.stml" target="_blank">Florida Public School Superintendents List</Button>
                </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                <div onMouseOver={() => setVideoClip(movie11)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                <Button href="https://www.fldoe.org/core/fileparse.php/7567/urlt/IDEADisGrantProDesc.pdf" target="_blank">BEESS Discretionary Project Contact List</Button>
                </div>
                </Col>
            </Row>
            <Row className="contact-list">
                <Col xs={12} md={6} lg={6}>
                <div onMouseOver={() => setVideoClip(movie12)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                    <Button href="https://www.fldoe.org/academics/exceptional-student-edu/staff/" target="_blank">BEESS Staff Contact Information</Button>
                </div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                <div onMouseOver={() => setVideoClip(movie13)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                    <Button href="https://www.fldoe.org/schools/k-12-public-schools/bosss/contact-us.stml" target="_blank">BoSSS Staff Contact Information</Button>
                </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12} lg={12}>
                <div onMouseOver={() => setVideoClip(movie14)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                    <iframe
                    width="100%"
                    height="900px"
                    title="Florida Statewide Discretionary Projects" src="https://www.eventsforlearning.com/sdp/sdp_map.php" />
                    </div>
                </Col>
            </Row>
            <Row>
                                <Col className="center">
                                    Institute for Small & Rural Districts<br />
                                    3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                        </Col>
                            </Row>
        </Container>
        </DashboardLayout>
        {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
