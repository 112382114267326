import React from 'react';
import ReactGA from 'react-ga';
import '../styles/sidebar.scss';
import '../styles/isrd.scss';
import map from '../images/ISRDMemberMap.png';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import panhandlePic from '../images/panhandle_region.png';
import nefecPic from '../images/nefec.png';
import heartlandPic from '../images/heartland.png';
import floridaPic from '../images/florida_pic.jpeg';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import movie1 from '../videos/01WhatIsISRD.mp4';
import movie2 from '../videos/02ProudlyServing.mp4';
import movie3 from '../videos/03AboutISRD.mp4';


export default function Home() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie1 | ''>(movie1);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie1);
    }

    const setVideoClip = (src: typeof movie1) => {
        setPlay(true);
        setClip(src)

    }

    return (
        <>
            <DashboardLayout>
                <Container fluid className="isrd">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Col className="animatedBanner" />
                            </Row>
                            <div onMouseOver={() => setVideoClip(movie1)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')} onTouchEnd={() => setVideoClip(movie1)} onTouchCancel={() => clearClip('') }>
                            
                                <Row>
                                    <Col md={5} lg={5} className="content">

                                            <h1>What is ISRD?</h1>
                                            <p>
                                                The Institute for Small and Rural Districts (ISRD) project provides training and technical assistance designed to support positive outcomes for students with disabilities and their families across a network of fifty (50) small and rural Florida school districts. The project assists district level administrators and school-based administrators as they implement the Individuals with Disabilities Education Act (IDEA 2004) regulations and the Florida State Performance Plan (SPP).
                                            </p>
                                            <p>
                                                Working in collaboration with the Bureau of Exceptional Student Education (BEES), Florida Department of Education, the goal of the ISRD project is that overall services to students with disabilities and those at risk will be enhanced and that increased student performance will be the end result of project activities.
                                            </p>
                                    </Col>
                                    <Col md={7} lg={7}>
                                        <img src={map} alt="map" className="map" />
                                    </Col>
                                </Row>
                            </div>

                                <Row>
                                    <Col className="headingBGSmall banner">
                                        <h2>Proudly Serving</h2>
                                    </Col>
                                </Row>
                            <div onMouseOver={() => setVideoClip(movie2)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                            <Row className='proudly-serving-row'>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle btn-block>
                                            Panhandle Region
                                </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>Bay County School District</Dropdown.Item>
                                            <Dropdown.Item>Calhoun County District Schools</Dropdown.Item>
                                            <Dropdown.Item>FAMU Lab School</Dropdown.Item>
                                            <Dropdown.Item>Florida State University Lab School</Dropdown.Item>
                                            <Dropdown.Item>Florida Department of Corrections</Dropdown.Item>
                                            <Dropdown.Item>Franklin County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Gadsden County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Gulf County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Holmes County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Jackson County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Jefferson County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Liberty County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Madison County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Taylor County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Wakulla County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Walton County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Washington County District Schools</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Row>
                                     <a href='http://my.paec.org/' className='center'><img src={panhandlePic} alt="" /></a>
                                    </Row>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle btn-block>
                                            North East Region
                                </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>Alachua County District School</Dropdown.Item>
                                            <Dropdown.Item>Baker County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Bradford County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Citrus County</Dropdown.Item>
                                            <Dropdown.Item>Columbia County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Dixie County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Flagler County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Florida School for the Deaf & Blind</Dropdown.Item>
                                            <Dropdown.Item>Gilchrist County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Hamilton County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Lafayette County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Levy County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Nassau County District Schools</Dropdown.Item>
                                            <Dropdown.Item>P.K. Yonge Developmental Research School (UF)</Dropdown.Item>
                                            <Dropdown.Item>Putnam County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Sumter County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Suwannee County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Union County District Schools</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Row>
                                     <a href='https://www.nefec.org/' className='center'><img src={nefecPic} alt="" /></a>
                                      </Row>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle btn-block>
                                            Heartland Region
                                </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>Charlotte County District Schools</Dropdown.Item>
                                            <Dropdown.Item>DeSoto County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Florida Alantic University Lab School</Dropdown.Item>
                                            <Dropdown.Item>Florida Virtual School</Dropdown.Item>
                                            <Dropdown.Item>Glades County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Hardee County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Hendry County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Highlands County District Schools</Dropdown.Item>
                                            <Dropdown.Item>IDEA Tampa</Dropdown.Item>
                                            <Dropdown.Item>Indian River County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Lake Wales Charter District</Dropdown.Item>
                                            <Dropdown.Item>Martin County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Monroe County District Schools</Dropdown.Item>
                                            <Dropdown.Item>Okeechobee County District Schools</Dropdown.Item>
                                            <Dropdown.Item>PB Charter Schools- KIPP Miami</Dropdown.Item>
                                            <Dropdown.Item>South Tech Academy</Dropdown.Item>
                                            <Dropdown.Item>UCP Charter Schools</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Row>
                                    <a href='https://www.heartlanded.org/' className='center'><img src={heartlandPic} alt="" className="heartland" /></a>
                                     </Row>
                                </Col>
                            </Row>
                            </div>
                            <hr />
                            <Row>
                                <Col>
                                    <div onMouseOver={() => setVideoClip(movie3)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                                        <h4>About ISRD</h4>
                                        <div className='home-bottom-ph'><p>
                                            The Institute for Small and Rural Districts (ISRD) is funded by the State of Florida, Department of Education, Bureau of Exceptional Student Education (BEESS) through federal assistance under the Individuals with Disabilities Act (IDEA), Part B. ISRD operates through NEFEC and proudly serves the districts of <a href='http://my.paec.org/'>PAEC</a>, <a href='https://www.nefec.org/'>NEFEC</a> and <a href='https://www.heartlanded.org/'>HEC</a>.
                                        </p></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                              
                            </Row>
                            <Row>
                                <Col className="center">
                                    Institute for Small & Rural Districts<br />
                                    3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                        </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </DashboardLayout>
            {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
