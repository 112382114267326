import React from 'react';
import ReactGA from 'react-ga';
import { HashLink } from 'react-router-hash-link';
import '../styles/isrd.scss';
import '../styles/sidebar.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { DashboardLayout } from '../components/layouts/Layout';
import { VideoPlayer } from '../components/VideoPlayer/VideoPlayer';
import movie3 from '../videos/03AboutISRD.mp4';
import movie5 from '../videos/05TargetAudience.mp4';
import movie6 from '../videos/06TopicsAddressed.mp4';
import movie7 from '../videos/07ServiceDelivery.mp4';
import movie8 from '../videos/08Products.mp4';
import pic1About from '../images/20220718_085235.jpg';
import pic2About from '../images/20220712_094609.jpg';
import pic3About from '../images/20220712_095132.jpg';


export default function About() {
    const TRACKING_ID = "UA-212424593-1";
    ReactGA.initialize(TRACKING_ID);
    const [clip, setClip] = React.useState<typeof movie5 | ''>(movie5);
    const [play, setPlay] = React.useState<boolean>(false);
    const [asl, setAsl] = React.useState(true);

    const clearClip = (src: string) => {
        setPlay(false);
        setClip(movie5);
    }

    const setVideoClip = (src: typeof movie5) => {
        setPlay(true);
        setClip(src);
    }

    return (
        <>
            <DashboardLayout>
                <div className="about-isrd">
                    <Container fluid className="isrd-mid">
                        <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Row>
                                        <Col className="headingBG banner">
                                            <h2>ABOUT US</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                    
                                    <div onMouseOver={() => setVideoClip(movie3)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                                       <h1 className="heading">Institute for Small & Rural Districts</h1>
                                       <p>The Institute for Small and Rural Districts (ISRD) is funded by the Florida Department of Education, Bureau of Exceptional Education and Student Services (BEESS) through federal assistance under the Individuals with Disabilities Act (IDEA), Part B. ISRD operates through the Putnam County School District and proudly serves the districts  in the panhandle (<a href="https://my.paec.org/" target="_blank" rel="noreferrer">PAEC</a>), northeast (<a href="https://www.nefec.org/" target="_blank" rel="noreferrer">NEFEC</a>) and heartland (<a href="https://www.heartlanded.org/" target="_blank" rel="noreferrer">HEC</a>) regions. PAEC), northeast (NEFEC) and heartland (HEC) regions. </p></div>
                                    
                                    </Row>
                                </Col>
                            </Row>
                        <Row className="header-columns">
                            <Col xs={12} md={3} lg={3} className="text-center">
                                <div onMouseOver={() => setVideoClip(movie5)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                                    <div className="targetedAudience"/>
                                    <ul>
                                        <li>Superintendents</li>
                                        <li>ESE Directors</li>
                                        <li>SS Directors</li>
                                        <li>School Administrators</li>
                                        <li>ESE Specialists</li>
                                        <li>School Counselors</li>
                                        <li>School Psychologists</li>
                                        <li>School Nurses</li>
                                        <li>Social Workers</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md={3} lg={3} className="text-center">
                                <div onMouseOver={() => setVideoClip(movie6)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                                    <div className="topicsAddressed"/>
                                <ul>
                                        <li>ESE Policy/Procedures</li>
                                        <li>Legal Issues</li>
                                        <li>Multi-Tiered System of Supports</li>
                                        <li>Universal Design for Learning</li>
                                        <li>Florida B.E.S.T Standards</li>
                                        <li>Mental Health</li>
                                        <li>Restorative Practices</li>
                                        <li>Disproportionate Discipline</li>
                                        <li>Moving Your Numbers: The Six Key Practices</li>
                                        <li>Understanding Poverty</li>
                                        <li>School Climate</li>
                                        <li>Chronic Absenteeism</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md={3} lg={3} className="text-center">
                                <div onMouseOver={() => setVideoClip(movie7)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                                    <div className="serviceDelivery"/>
                                    <ul>
                                        <li>Professional Development</li>
                                        <li>Coaching and Mentoring for ESE Administrators</li>
                                        <li>School District Peer-to-Peer Collaboration</li>
                                        <li>Onsite Technical Assistance</li>
                                        <li>Book Walks</li>
                                        <li>Purchase and Distribution of Media</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md={3} lg={3} className="text-center">
                                <div onMouseOver={() => setVideoClip(movie8)} className={asl ? 'active-video' : ''} onMouseLeave={() => clearClip('')}>
                                <div className="products"/>
                                    <ul>
                                        <li>ISRD Website</li>
                                        <li className='about-link'><HashLink to="/newsletters">Quarterly Newsletter</HashLink></li>
                                        <li className='about-link'><HashLink to="/annual-report">Annual Report</HashLink></li>
                                        <li className='about-link'><HashLink to="/resources/recorded-webinars">Recorded Webinars</HashLink></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="isrd">
                    <Row>
                                        <Col className="midPage">
                                            <h2>ISRD Event Photos</h2>
                                        </Col>
                                    </Row>
                                    <Row className="pics-row">
                                    <Col xs={12} md={4} lg={4}><img src={pic1About} alt="" />
                                    <div><h5>PAEC Summer Leadership Conference</h5></div>
                                    <div><p>PAEC Executive Director John Selover and ISRD Program Administrator Chastity Lee</p></div></Col>
                                    <Col xs={12} md={4} lg={4}><img src={pic2About} alt="" />
                                    <div><h5>NEFEC Summer Leadership Conference</h5></div>
                                    <div><p>District Leaders collaborating at one of the NEFEC SLC breakout sessions.</p></div></Col>
                                    <Col xs={12} md={4} lg={4}><img src={pic3About} alt="" />
                                    <div><h5>NEFEC Summer Leadership Conference</h5></div>
                                    <div><p>District Leaders collaborating at one of the NEFEC SLC breakout sessions.</p></div></Col>
                                    </Row>
                        <Row className="pics-row">
                            <Col className="center">
                                Institute for Small & Rural Districts<br />
                                3841 Reid Street, Palatka, FL 32177 | phone: 386.329.3894 | fax: 386.329.3684
                            </Col>
                        </Row>
                    </Container>
                </div>
            </DashboardLayout>
            {
                asl && (
                    <VideoPlayer src={clip} playVideo={play} />
                )
            }
            {
                !asl && ''
            }
        </>
    )
}
